* 
  box-sizing: border-box
  color: #fff

:root
  --borderRadius: 10px 10px

html
  overflow-x: hidden !important
  overflow-y: overlay

body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow: hidden !important

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace

body::-webkit-scrollbar
  width: 0.3rem

body::-webkit-scrollbar-track
  box-shadow: inset 0 0 3px #1d1d1d

body::-webkit-scrollbar-thumb
  background-color: #272727
  outline: 1px solid #212222

::-moz-selection
  background: rgba(73, 5, 186, 0.99)
  color: #fff

::-webkit-selection
  background: rgba(73, 5, 186, 0.99)
  color: #fff

::selection
  background: rgba(73, 5, 186, 0.99)
  color: #fff

body,html
  min-width: 360px
  background-color: #000
  padding: 0
  margin: 0
  -webkit-tap-highlight-color: transparent !important

p, h1, h2, h3, h4, h5, h6, span
  line-height: 1.5

ul, li
  margin: 0
  padding: 0

img
  user-select: none

@media not all and (min-resolution:.001dpcm)
  img[loading="lazy"]
    clip-path: inset(0.5px)